import { NgForOf } from '@angular/common';
import {Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@/shared/lib/modules/core.module';
import { MaterialModule } from '@/shared/lib/modules/material.module';

import {DeploymentDialog} from '../../interfaces/deployment-dialog';

@Component({
  selector: 'app-deployment-select-dialog',
  templateUrl: './deployment-select-dialog.component.html',
  styleUrl: './deployment-select-dialog.component.css',
  standalone: true,
  imports: [CoreModule],
})
export class DeploymentSelectDialogComponent  {

  form: FormGroup;
  dataList: any[];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeploymentSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeploymentDialog ) {

    this.dataList = data?.deploymentList;

    this.form = fb.group({
      deployment: data?.selectedDeployment,
    });



  }


  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

}
