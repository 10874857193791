import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpinnerComponent } from '@/app/interfaces/spinner/spinner.component';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { AppLoadingNotification } from "../../interfaces/app-loading-notification";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ods-loading',
  templateUrl: './app-loading.component.html',
  styleUrl: './app-loading.component.css',
  standalone: true,
  imports: [CoreModule, SpinnerComponent],
})
export class AppLoadingComponent {

  @Input() appLoadingNotification: AppLoadingNotification | null = null;

  constructor(
  ) {}

}
