import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CoreModule } from '@/shared/lib/modules/core.module';

import { TenantDialog } from '../../interfaces/tenant-dialog';

@Component({
  selector: 'app-tenant-select-dialog',
  templateUrl: './tenant-select-dialog.component.html',
  styleUrl: './tenant-select-dialog.component.css',
  standalone: true,
  imports: [CoreModule],
})
export class TenantSelectDialogComponent {

  form: FormGroup;
  tenantList: any[];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TenantSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TenantDialog ) {

    this.tenantList = data?.tenantList;

    this.form = fb.group({
      tenant: data?.selectedTenant,
    });

  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

}
