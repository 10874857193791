import { inject, Injectable } from '@angular/core';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { TenantsApi } from '@/app/services/api/tenants.api';

@Injectable({
    providedIn: 'root'
})
export class TenantService {
    deploymentStore = inject(DeploymentStore);
    tenantsApi = inject(TenantsApi);

    initialize() {
        this.getTenantList();
    }

    getTenantList(): any {
        this.tenantsApi.getAll().subscribe((data: any) => {
            if (isNotNullOrEmpty(data)) {
                this.deploymentStore.setTenantList(data);
            }
        });
    }
}
