import { AsyncPipe, CommonModule, DatePipe, NgForOf, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '@/shared/lib/modules/material.module';
import { DateExtension } from '../../../../web-app/src/app/services/extensions/date.extension';


@NgModule({
  imports: [
    AsyncPipe,
    NgForOf,
    NgIf,
  ],
  exports: [
    AsyncPipe,
    CommonModule,
    FormsModule,
    MaterialModule,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
  ],
  providers: [
    DateExtension,
  ]
})
export class CoreModule { }
