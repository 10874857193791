@if(this.appLoadingNotification?.isLoading) {
  <div class="overlay">
    <div class="spinner-wrapper">
      <app-spinner />
      <table>
        <thead>
          <th>Item</th>
          <th>Status</th>
        </thead>
        <tbody>
        <tr *ngFor="let notification of this.appLoadingNotification?.notificationList" style="z-index: 1050">
          <td class="default-color" matListItemTitle style="z-index: 1050" >{{notification.description}}</td>
          <td matListItemLine style="z-index: 1050" [class]="notification.isLoaded ? 'loaded' : 'loading'">{{notification.isLoaded ? 'Loaded' : 'Loading'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
}
