<mat-toolbar class="header-gradient fullwidth">
  <button aria-label="Menu button" color="secondary" mat-icon-button (click)="drawer.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <img alt="cloudOTDR-logo" height="40px" src="../assets/logo/logo.svg" style="padding: 16px">
  <span>
    {{ this.deploymentStore.selectedDeployment()?.name }}
    <div class="menu-content-right">{{this.deploymentStore.selectedTenant()?.name }}</div>
  </span>

</mat-toolbar>
<mat-drawer-container autosize class="main-container" [hasBackdrop]="true">
  <!-- Side Navigation -->
  <mat-drawer #drawer class="sidenav" mode="side" [mode]="'over'">
    <ods-nav (click)="drawer.close()" />
  </mat-drawer>
  <!-- Content -->
  <div class="content">
    <div class="wrapper">
      <div class="row outline">
        <ods-loading [appLoadingNotification]="this.notificationList | async" />
      </div>

      <ng-content />
    </div>
    <mat-drawer-container class="page-container">
      <router-outlet />
    </mat-drawer-container>
  </div>
</mat-drawer-container>
