<mat-dialog-content class="p-0" [formGroup]="form">
  <mat-form-field class="fullwidth">
    <mat-select class="fullwidth" formControlName="tenant" placeholder="Select tenant">
      <mat-option *ngFor="let tenant of this.tenantList" [value]="tenant.id">
        {{tenant.name }}
      </mat-option>
    </mat-select>

  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button color="primary"  mat-raised-button (click)="save()">
    Save
  </button>
  &nbsp;
  <button color="accent"  mat-raised-button (click)="close()">
    Close
  </button>
</mat-dialog-actions>

