import { Component, Input } from '@angular/core';

import { CoreModule } from '@/shared/lib/modules/core.module';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  standalone: true,
  imports: [CoreModule],
})
export class SpinnerComponent  {

  @Input() message = '';

  constructor() { }
}
