import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DevicesApi } from '@/app/services/api/devices.api';

@Injectable({
    providedIn: 'root'
})
export class DeploymentService {
  devicesApi = inject(DevicesApi);
  http = inject(HttpClient);
  dialog = inject(MatDialog);

  public getDevices(tenant: number) {
    return new Promise<any[]>((resolve) => {
      this.devicesApi.getDevicesData(tenant).subscribe((data: any) => {
        resolve(data);
      });
    });
  }
}
