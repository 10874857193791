import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { DeploymentService } from '@/app/services/deployment/deployment.service';
import { TenantService } from '@/app/services/tenants/tenant.service';
import { AppLoadingComponent } from '@/app/shared/app-loading/app-loading.component';
import { NavComponent } from '@/app/shared/nav/nav.component';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { UserStore } from '@/shared/lib/stores/user.store';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { NotificationStore } from '@/shared/lib/stores/notification.store';
import { AppLoadingNotification, AppLoadingNotificationItem } from '@/app/interfaces/app-loading-notification';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  standalone: true,
  imports: [CoreModule, CommonModule, AppLoadingComponent, RouterOutlet, NavComponent, TranslateModule],
})
export class AppComponent {
  userStore = inject(UserStore);
  deploymentStore = inject(DeploymentStore);
  notificationStore = inject(NotificationStore);

  tenantService = inject(TenantService);
  devices = inject(DeploymentService);
  translate = inject(TranslateService);

  title = 'cloudOTDR';
  prevAuthStatus = false;
  notificationList = toObservable<AppLoadingNotification | null>(this.notificationStore.notificationData);

  constructor() {
    this.translate.setDefaultLang('en');
    this.loadCoreData();
  }

  loadCoreData() {
    toObservable(this.userStore.isAuthenticated).subscribe((isAuthenticated) => {
      if (isAuthenticated && !this.prevAuthStatus) {
        this.tenantService.initialize();
        this.prevAuthStatus = isAuthenticated;
        this.translate.setDefaultLang(this.userStore.displayLanguage());
      }
    });
  }
}
