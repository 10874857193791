<mat-dialog-content class="p-0" [formGroup]="form">
  <div [innerHTML]="this.data.description"></div>
  <mat-form-field class="fullwidth">
    <mat-select class="fullwidth" formControlName="deployment" placeholder="Select deployment">
      <mat-option *ngFor="let data of this.dataList" [value]="data.id">
        {{data.name }}
      </mat-option>
    </mat-select>

  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button color="primary"  mat-raised-button (click)="save()">
    Save
  </button>

  <button color="accent"  mat-raised-button (click)="close()">
    Close
  </button>
  </mat-dialog-actions>

