import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@/environments/environment';
import { TenantEdit } from '@/app/interfaces/api/tenant-edit';
import { Tenant } from '@/app/interfaces/api/tenant';
import { Feature } from '@/app/interfaces/api/tenant-feature';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantsApi {
  http = inject(HttpClient);

  public getTenantByHeader(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${environment.data.baseUrl}/tenants/byheader`);
  }

  public getAll(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${environment.data.baseUrl}/tenants/all`);
  }

  public getAllFeatures(): Observable<Feature[]> {
    return this.http.get<Feature[]>(`${environment.data.baseUrl}/features`);
  }

  public createTenant(tenant: TenantEdit): Observable<Tenant> {
    return this.http.post<Tenant>(`${environment.data.baseUrl}/tenants`, tenant);
  }

  public editTenant(tenant: TenantEdit): Observable<void> {
    return this.http.put<void>(`${environment.data.baseUrl}/tenants/${tenant.id}`, tenant);
  }

  public removeTenant(tenant: TenantEdit): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.data.baseUrl}/tenants/${tenant.id}`);
  }

}
